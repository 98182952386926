
	import { Component, Emit, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
	import { PropType } from 'vue';
	import { Project} from '@/modules/projects/types/entities';
	import {
		Actions as SupplierActions,
		Getters as SupplierGetters
	} from '@/modules/entities/store/modules/suppliers/types/StoreTypes';
	import { namespace } from 'vuex-class';
	import { Supplier } from '@/modules/entities/types/entities';
	import { debounce } from 'lodash';
	import AppTextareaComponent from '@/core/components/app-textarea/app-textarea.component.vue';
	import AppInputComponent from '@/core/components/app-input/app-input.component.vue';
	import { Team } from '@/modules/settings/types/entities';
	import { Quote } from '@/modules/calendar/interfaces/project/quote';

	const projects = namespace('projects');
	const suppliers = namespace('entities/suppliers');

	@Component({
		components: {
			'app-textarea': AppTextareaComponent,
			'app-input': AppInputComponent
		}
	})
	export default class QuoteModalComponent extends Vue {
		@suppliers.Action(SupplierActions.SET_CONTRACTORS_FILTER_NAME_QUERY) setContractorsNameQueryFilter!: (payload: string) => void;
		@suppliers.Action(SupplierActions.SET_SUPPLIERS_FILTER_CLEAR_NO_FETCH) setClearFilters!: () => void;

		@suppliers.Getter(SupplierGetters.GET_LOADING) contractorsLoading!: boolean;
		@suppliers.Getter(SupplierGetters.GET_FETCHED_CONTRACTORS) contractors!: Array<Supplier | Team>;

		@PropSync('showModal', { type: Boolean, default: false }) showModalSync!: boolean;
		@Prop({ type: Object as PropType<Project>, required: true }) project!: Project;
		@Prop({ type: Object as PropType<Quote>, required: true }) quote!: Quote;

		contractorsSearchValue = '';

		destroyed(): void {
			this.setClearFilters();
		}

		@Emit('onQuoteSave')
		emitOnQuoteSave(): Quote {
			this.showModalSync = false;
			return this.quote;
		}

		@Watch('contractorsSearchValue', { immediate: true })
		onSupplierSearchValueChange = debounce((value: string) => {
			this.setContractorsNameQueryFilter(value);
		}, 350);
	}
